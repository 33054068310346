<template>
  <AuthLayout title="Login">
    <FormLogin/>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import FormLogin from '../../components/FormLogin'

export default {
  name: 'Login',

  components: {
    AuthLayout,
    FormLogin,
  },
}
</script>
