<template>
  <v-form
    @submit.prevent="login(form)"
  >
    <v-text-field
      v-model="form.email"
      label="Email"
      required
      autocomplete="username"
    >
    </v-text-field>

    <v-text-field
      v-model="form.password"
      label="Password"
      type="password"
      required
      autocomplete="current-password"
    >
    </v-text-field>

    <v-btn
      type="submit"
      color="primary"
    >
      Log in
    </v-btn>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormLogin',

  data: () => ({
    form: {
      email: '',
      password: '',
    },
  }),

  methods: {
    ...mapActions('user', ['login']),
  },
}
</script>
